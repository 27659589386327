//Colors

//Shadow scale//
$shadow_blackGradient: linear-gradient(rgba(2, 2, 2, 0.7), rgba(0, 0, 0, 0.7)); //cta
$shadow_blackLight: 0px 0px 20px 0px rgba(0, 0, 0, 0.1); // header
$shadow_cards: 0px 2px 15px rgba(0, 0, 0, 0.1); // cards why us
$shadow_cardsHover: 0px 5px 25px rgba(0, 0, 0, 0.15); //cards hover why us
$shadow_blackLight2:  rgba(13, 30, 45, 0.6); // carousel opacity
$shadow_dropDown: 0px 0px 30px rgba(127, 137, 161, 0.25); //drop-down
$shadow_black: rgba(255, 255, 255, 0.1); //  why us more btn
$shadow_black2:  rgba(0, 0, 0, 0.2) 1px 1px 5px 0px; //shadow btns (social btns)
$shadow_input: 0 0 0 0.2rem rgba(16, 52, 83, 0.25); //shadow inputs on login view

//Gray scale//
$color_white: #FFFFFF;
$color_ligthGray: #f4f3f3; //custom section
$color_lightGray2: #e3f0fc; //mobile links
$color_ligthGray3: #f6f6f7; //custom section clients
$color_ligthGray4: #f9f8f9; // footer top
$color_ligthGray5: #f1f0f2; // footer
$color_ligthGray6: rgb(151, 160, 175); //light text
$color_ligthBlack:rgba(255, 255, 255, 0.7); //portfolio p
$color_secondaryFonts: #848484; //why us p
$color_globalFonts: #444444; //fonts
$color_titleFonts:#36343a;


//Blue scale//
$color_blackBlueBackground: #1f3548; //mobile menu
$color_blackBlueOpacity:  rgba(31, 53, 72, 0.7); //opacity when show menu
$color_blueLink2: #103453; // dropdown links
$color_blackBlue: #384259; //palette
$color_blackBlueHover: #475471; 
$color_blueLink: #5c768d; //menu links
$color_blue: #428bca; // hover menu/dropdown
$color_lightBlue: #9eccf4; //mobile hover links

//Red scale//
$color_redSolkos: #e03b4e;
$color_redSolkos2:#e82d5d; //palette
$color_redSolkos1:#ec5078; 
$color_redSolkos3:#f4648c;



//Functions

//Shade of a color
@function shades($color, $change: n) {
    @if $change == n {
      @return $color;
    }
    @if $change == l1 {
      @return lighten($color, 10%);
    }
    @if $change == l2 {
      @return lighten($color, 25%);
    }
    @if $change == l3 {
      @return lighten($color, 50%);
    }
    @if $change == l4 {
      @return lighten($color, 75%);
    }
    @if $change == d1 {
      @return darken($color, 10%);
    }
    @if $change == d2 {
      @return darken($color, 25%);
    }
    @if $change == d3 {
      @return darken($color, 50%);
    }
    @if $change == d4 {
      @return darken($color, 75%);
    }
  }
  /*
    Exaple usage
  
    $base-color:#00A4FF; // color base
  
    .shadesl div:nth-of-type(1){   //element selected
      background-color:$base-color; //normal variable usage
    }
    .shadesl div:nth-of-type(2){
      background-color:shades($base-color,l1); //usage with a function shades with params: variable color, condition
    }
    .shadesl div:nth-of-type(3){
      background-color:shades($base-color,l2); //l2 condition example
    }
  */