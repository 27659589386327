@import "../../../scss/variables";

.title-options{
    ul {
        list-style-type: none;
        text-align: center
      }
      
    li {
        display: inline;
        margin-right: 10px;

        .sty-users-search{
            left: 342px!important; //2:80px, 3:144px, 4: 255px, 5: 316px
        }
    }
}

.editable-rows.active{
  .deviceId, .sty-lastUpdate{
    display: none!important;
  }
  .editDevice, .sty-operations{
    display: block!important;
  }
}

.sty-operations, .sty-lastUpdate {
  .ant-tag{
    padding-left: 20px!important;
    padding-right: 20px!important;
  }

  .actionLock{
    font-size: 18px;

    &.openLock {
      color: #21d8bf!important;
      &:hover{
        color: #00bba2!important;
        transition: .3s;
      }
    }
    &.closeLock {
      color: #dc7993!important;
      &:hover{
        color: #d63d66!important;
        transition: .3s;
      }
    }
  }

  .device-blocked{
    color: #d454a0!important;
    background: #f9d9e5!important;
    border-color: #cd3753!important;
  }
  
}

.editDevice{
  font-size: 15px;

  i.bx-trash-alt, .cancel{
    color: #d82143 !important;
    &:hover{
      color: #a5122d!important;
      transition: .3s;
    }
  }
  i.bx-edit-alt{
    color: #218cd8 !important;
    &:hover{
      color: #16649c!important;
      transition: .3s;
    }
  }

  .cancel{
    font-size: 20px;
  }
  .save{
    font-size: 20px;
    color: #6c757d;
    &:hover{
      color: #494e52!important;
      transition: .3s;
    }
  }
}

.sty-tagTrack{

  .ant-tag{
    min-width: 77px!important;
  }
  // text-align: center;
}

th.sty-tagTrack{
  padding-left: 25px;
}

.sty-users-mode{
  font-size: 18px;
  .bx-sync{
      font-size: 22px;
      color: #eeeeee!important;
      text-shadow: 0px 0px 2px #444;
  }
  .sty-text{
      font-size: 16px!important;
      color: #bebebe!important;
      text-shadow: 1px 0px 0px #444;
  }
}

.sty-users-status{
  font-size: 18px;
  .bxs-x-circle,.sty-status-x{
      color: #DC0F0F!important;
  }

  .bxs-check-circle,.sty-status-check{
      color: #46994E!important;
  }

  .sty-status-x,.sty-status-check{
      font-family:  Arial, Helvetica, sans-serif;
      font-size: 16px!important;
  }
}

.sty-users-create-new{
  color: #16649c;
  &:hover{
      color: #2698e9;
  }
}

.sty-users-delete:hover{
  color: #e41e60c7;
}

.sty-users-activate:hover{
  color: #18d3bac7;
}

.sty-shield{
    .bx-check-shield{
        font-size: 29px;
    }
    .active{
        color: #12c7afc7;
    }
}

.cirle-name{
    width: 33px;
	height: 33px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
    margin:0px auto;
    padding:3%;

        &.category-0{
            background: rgb(211, 51, 11);
        }

        &.category-1{
            background: rgb(202, 136, 14);
        }

        span{
            font-family: sans-serif!important;
            color: white!important;
            font-size: 10px!important;
            font-weight: bold!important;
        }
}

