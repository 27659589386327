@import "../variables";

.navbar {
    position: relative;
    /* display: flex;
    flex-wrap: wrap;
    // align-items: center;
    justify-content: right; */
}

   

    .header {
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    
        &__content {
            /* margin-left: 16px;
            color: #444444!important;
            display: inline-block;
            line-height: inherit!important;
            white-space: nowrap; */
    
            &-logo {
                height: 100%;
                display: inline-block;
                vertical-align: top;
                padding: 0 0.25rem;
                border-radius: 0.25rem;
              
                & img {
                    max-height: 2.25rem;
                    max-width: 6rem;
                    vertical-align: middle;
                }
            }
    
            &-brandName {
                display: inline-block;
                height: 100%;
                max-width: 100%;
                box-sizing: border-box;
                padding: 0 0.625rem;
                border-radius: 0.25rem;
                margin-left: 0.5rem;
            
                & h1{
                    font-weight: bold;
                    color: #fff!important;
                    margin-bottom: 0px;
                    transform: translateY(4%);
                }
            }
    
            &-logout-btn, &-logout-btn:hover, &-logout-btn:active, &-logout-btn:focus  {
    
               /*  position: absolute!important;
                right: 1.7%; */
                border-radius: 50%;
                -webkit-border-radius: 50%;
                background-color: #e82d5d;
                border: 1px solid;
                border-color: #e03b4e;
        
                .avatarInitialsHolder i, .avatarInitialsHolder i:hover, .avatarInitialsHolder i:focus{
                    color: #fff;
                    position: absolute!important;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    -webkit-transform: translate(-50%, -50%);
                    font-size: large;
                }
            }
        }
    
        
    }

    

.ant-btn:focus > span.avatarInitialsHolder{
    position: absolute;
}


.ant-layout-header {
    height: 3.8rem!important;
    padding: 0px;
    background: #001529!important;
    color: #444444!important;
    line-height: inherit!important;
}

.sty-popoerNavbat .ant-popover-content .ant-popover-inner .ant-popover-inner-content a{
    // background-color: #f9f8f9!important;
    color: #5c768d;
}

.sty-qr .sty-qrMessage{
    max-width: 188px;
    font-size: 8px;
    color: #444444;
}

.ant-popover-inner-content .sty-qrTitle{
    color: #5c768d!important;
}

.sty-qr .sty-inputForm{
    width: 200px; 
}

.sty-nabSession{
    list-style: none;
    padding-left: 0px;
    &li{
        position: relative;
        white-space: nowrap;
        float: left!important;
        list-style: none;
    }
}