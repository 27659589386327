.createBtn {
    background-color: #0A84B0!important;
    border-color: #0A84B0!important;
    color: #ffffff!important;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;

      &:focus{
        background-color: #0A84B0!important;
        border-color: #0A84B0!important;
        color: #ffffff!important;
      }
  
      &:hover{
        background-color: #195772!important;
        border-color: #195772!important;
      }
  }

  .sty-boardButton{
    top: 8px;
    left: 8px;
  }

  .deviceId {
    color: #6c757d;
    border-color: #6c757d;
    border-right: none;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }

  .infoBtn{
    color: #17a2b8;
    border-color: #17a2b8;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;

    &:focus{
      color: #17a2b8;
      border-color: #17a2b8;
    }

    &:hover{
      color: #fff;
      background-color: #17a2b8;
      border-color: #17a2b8;
      }
  }
  
  .closeBtn{
    color: #6c757d;
    border-color: #6c757d;
    border-right: none;
    border-top-left-radius: 15px;

    &:focus{
      color: #6c757d;
      border-color: #6c757d;
      border-right: none;
    }

    &:hover{
      color: #fff;
      background-color: #6c757d;
      border-color: #6c757d;
    }
  }
  
  .openBtn{
    color: #6c757d;
    border-color: #6c757d;
    border-bottom-right-radius: 15px;

    &:focus{
      color: #6c757d;
      border-color: #6c757d;
    }

    &:hover{
      color: #fff;
      background-color: #6c757d;
      border-color: #6c757d;
    }
  }

  .deleteBtn{
    color: #dc3545;
    border-color: #dc3545;
    border-bottom-right-radius: 15px;

    &:focus{
      color: #dc3545;
      border-color: #dc3545;
    }

    &:hover{
      color: #fff;
      background-color: #dc3545;
      border-color: #dc3545;
    }
  }

  .sty-modalDevicesList {
    .ant-modal-content .ant-list-items{
      height: 400px!important;
      overflow: hidden; 
      overflow-y: scroll;
    }

    .ant-modal-content,.ant-modal-header,.ant-input-group{
      border-radius: 15px!important;
    }
    #search-input{
      border-top-left-radius: 15px!important;
      border-bottom-left-radius: 15px!important;
    }
    .ant-input-search-button{
      border-top-right-radius: 15px!important;
      border-bottom-right-radius: 15px!important;
    }
    
  }


  .tableauReport{
    iframe{
      width: 100%!important;
    }
  }