@import "../variables";

.resetPwd {
    background-position: center center;

    &__component {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-height: 100vh;
    }
  
    &__content {
      position: absolute;
      top: 50%;
      left: 50%;
      -moz-transform: translateX(-50%) translateY(-50%);
      -webkit-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
  
      &-logo {
        padding: 35px 0 30px 0;
        width: 93%;
        display: -moz-flex;
        -moz-flex-pack: center;
        -moz-flex-align: center;
        display: -webkit-flex;
        -webkit-flex-pack: center;
        -webkit-flex-align: center;
        display: flex;
        box-pack: center;
        box-align: center;
      
        &>ul>li {
          position: relative;
          white-space: nowrap;
          float: left!important;
        }
      
        & img {
          height: 60px;
  
          &.sty-brandname{
            height: 50px!important;
            padding-left: 10px;
          }
        }
        &-sty {
          list-style: none;
  
          & .worldIcon{
            color: #5c768d;
            font-size: 21px;
          }
  
          & .worldIcon:hover{
            color: #428bca;
          }
  
          & .drop-down ul {
            margin: 0;
            padding: 0;
            list-style: none;
            position: absolute;
            display: block;
            position: absolute;
            z-index: 99;
            opacity: 0!important;
            visibility: hidden!important;
            color: $color_globalFonts;
            transition: .3s;
  
            & span:hover{
              color: $color_redSolkos1!important;
            }
            
          }
  
          & .drop-down:hover>ul {
            opacity: 1!important;
            top: 100%;
            visibility: visible!important;
          }
         
  
  
  
  
        }
      }
  
      &-card {
        display: flex;
        position: relative;
        flex-direction: column;
        margin: 0px auto 24px;
        width: 400px;
        padding: 32px 40px 32px;
        background: $color_white;
        border-radius: 35px;
        box-shadow: $shadow_cards;
        box-sizing: border-box;
        color: $color_globalFonts;
  
        &-title {
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          flex-direction: column;
          text-align: center;
          margin-bottom: 24px;
          color: $color_globalFonts;
          font-size: 16px;
        }
  
        //*********************** (Generico) ****************************
        & h5 {
          color: $color_globalFonts;
          font-weight: 600;
          font-size: 1em;
        }
  
        & a,
        & .toggleRegistry {
          color: $color_blue;
          cursor: pointer;
        }
        //*********************** (Generico) ****************************
  
      }
  
  
    }
  }