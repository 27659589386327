@import "../variables";

.sty-devices-search{
  ul {
    list-style-type: none;
    text-align: center
  }
  
li {
    display: inline;
    margin-right: 10px;

    .bx-search{
        left: 80px!important; //2:80px, 3:144px, 4: 255px, 5: 316px
    }
}
}

.editable-rows.active{
  .deviceId, .sty-lastUpdate{
    display: none!important;
  }
  .editDevice, .sty-operations{
    display: block!important;
  }
}

.sty-operations, .sty-lastUpdate {
  .ant-tag{
    padding-left: 20px!important;
    padding-right: 20px!important;
  }

  .actionLock{
    font-size: 18px;

    &.openLock {
      color: #21d8bf!important;
      &:hover{
        color: #00bba2!important;
        transition: .3s;
      }
    }
    &.closeLock {
      color: #dc7993!important;
      &:hover{
        color: #d63d66!important;
        transition: .3s;
      }
    }
  }

  .device-blocked{
    color: #d454a0!important;
    background: #f9d9e5!important;
    border-color: #cd3753!important;
  }
  
}

.editDevice{
  font-size: 15px;

  i.bx-trash-alt, .cancel{
    color: #d82143 !important;
    &:hover{
      color: #a5122d!important;
      transition: .3s;
    }
  }
  i.bx-edit-alt{
    color: #218cd8 !important;
    &:hover{
      color: #16649c!important;
      transition: .3s;
    }
  }

  .cancel{
    font-size: 20px;
  }
  .save{
    font-size: 20px;
    color: #6c757d;
    &:hover{
      color: #494e52!important;
      transition: .3s;
    }
  }
}

.sty-tagTrack{

  .ant-tag{
    min-width: 77px!important;
  }
  // text-align: center;
}

th.sty-tagTrack{
  padding-left: 25px;
}

