@import '~antd/dist/antd.css';

section.ant-layout{
    padding-top: 0px;
    background: #f0f2f5!important;
}

/* .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
  } */

.logo__content {
    margin-left: 16px;
    margin-bottom: 56px;
    color: #fff!important;
    display: inline-block;
    padding-top: .3125rem!important;
    padding-bottom: .3125rem!important;
    margin-right: 1rem;
    line-height: inherit!important;
    white-space: nowrap;

  &-logo {
    height: 100%;
    display: inline-block;
    vertical-align: top;
    padding: 0 0.25rem;
    border-radius: 0.25rem;
  
    & img {
        max-height: 2.25rem;
        max-width: 6rem;
        vertical-align: middle;
    }
  }

  &-brandName {
    display: inline-block;
    height: 100%;
    max-width: 100%;
    box-sizing: border-box;
    padding: 0 0.625rem;
    border-radius: 0.25rem;
    margin-left: 0.5rem;

    & h1{
        font-weight: bold;
        color: #fff!important;
        margin-bottom: 0px;
        padding-top: 12%;
    }
  }
}

.ant-layout .site-layout {
    background-color: #f0f2f5!important;
}

.site-layout .site-layout-background {
    background: #f0f2f5 !important;
    min-height: 74vh!important;
}

.sty-menuSidebar {
  padding-top: 3.5rem!important;
}

.sty-sider{
  margin-bottom: -1%;
}

.ant-menu-dark.sty-menuSidebar.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #428bca!important;
}

  [data-theme="dark"] .site-layout .site-layout-background {
    background: #141414;
  }


.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger.sty-sider{
  width: 72px!important;
  flex: 0 0 72px!important;
  min-width: 72px!important;
  max-width: 72px!important;

  .ant-layout-sider-trigger{
    width: 72px!important;
  }
}

.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-has-trigger.sty-sider{
  width: 200px!important;
  flex: 0 0 200px!important;
  min-width: 200px!important;
  max-width: 200px!important;

  .ant-layout-sider-trigger{
    width: 200px!important;
  }
}

.title{
  font-size: 21px;
  height: 21px;
  font-weight: 600;
}

.search-box{
  border: 1px solid #dcdcdc;
  background: #fafafa;
  /* z-index: 23;
  margin: 0px auto; */
  border-radius: 15px;
  display: table-cell;
  width: 300px;

  i.bx-search{
    top: 35%;
    left: 55px;
    transform: translate(-50%, -50%);
    align-self: center;
    order: 1;
    padding: 0px 5px;
    height: 14px;
    font-size: 14px;
    color: rgb(153, 153, 153);
    float: left;
    position: absolute;
  }
  input{
    margin-left: 20px;
    box-shadow: none!important;
    outline: none;
    font-weight: 400;
    order: 1;
    color: #444444;
    width: 100%;
    margin: 0 0 0 10px;
    margin-left: 25px;
    padding: 0px;
    border-radius: 2;
    border: 0px!important;
    background: transparent;
    
    &:hover{
      border: none!important;
      box-shadow: none!important;
    }
  }
}



.sty-downloadTable{
  font-size: 33px!important;
  // padding-right: 20px;
  vertical-align: middle;
  top: 18%;
  transform: translate(-50%, -50%);
  align-self: center;
  order: 1;
  padding: 0px 5px;
  height: 14px;
  font-size: 14px;
  color: #999999;
  position: absolute;
  // left: 15px;
}


