@import "../variables";

.login {
  background-position: center center;

  &__component {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    

    &-logo {
      padding: 35px 0 30px 0;
      width: 93%;
      display: -moz-flex;
      -moz-flex-pack: center;
      -moz-flex-align: center;
      display: -webkit-flex;
      -webkit-flex-pack: center;
      -webkit-flex-align: center;
      display: flex;
      box-pack: center;
      box-align: center;
      

      &>ul>li {
        position: relative;
        white-space: nowrap;
        float: left!important;
      }
    
      & img {
        height: 60px;

        &.sty-brandname{
          height: 50px!important;
          padding-left: 10px;
        }
      }

      &-sty {
        list-style: none;

        & .worldIcon{
          color: #5c768d;
          font-size: 21px;
        }

        & .worldIcon:hover{
          color: #428bca;
        }

        & .drop-down ul {
          margin: 0;
          padding: 0;
          list-style: none;
          position: absolute;
          display: block;
          position: absolute;
          z-index: 99;
          opacity: 0!important;
          visibility: hidden!important;
          color: $color_globalFonts;
          transition: .3s;

          & span:hover{
            color: $color_redSolkos1!important;
          }
          
        }

        & .drop-down:hover>ul {
          opacity: 1!important;
          top: 100%;
          visibility: visible!important;
        }
       




      }
    }

    &-card {
      display: flex;
      position: relative;
      flex-direction: column;
      margin: 0px auto 24px;
      width: 400px;
      padding: 32px 40px 20px;
      background: $color_white;
      border-radius: 35px;
      box-shadow: $shadow_cards;
      box-sizing: border-box;
      color: $color_globalFonts;

      &-title {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        margin-bottom: 24px;
        color: $color_globalFonts;
        font-size: 16px;
      }

      //*********************** (Generico) ****************************
      & h5 {
        color: $color_globalFonts;
        font-weight: 600;
        font-size: 1em;
      }

      & a,
      & .toggleRegistry {
        color: $color_blue;
        cursor: pointer;
      }
      //*********************** (Generico) ****************************

    }


  }
}
/* STYLES FOR STY INPUT FORM BUTTON */
.sty-inputForm:first-child {
  margin-top: 0px;
}
.sty-inputForm {
  background-color: $color_ligthGray5 !important;
  border-color: shades($color_ligthGray5, "d1") !important;
  color: $color_blackBlueBackground !important;
  font-size: 14px !important;
  border-radius: 15px;
  border-style: solid;
  margin-bottom: 12px;

  &:hover {
    background-color: $color_white !important;
    box-shadow: $shadow_input !important;
    border-color: shades($color_ligthGray5, "d2") !important;
  }
  /* &:focus {
    background-color: $color_white !important;
    box-shadow: $shadow_input !important;
  } */
}
/* END STYLES FOR STY INPUT FORM BUTTON */

/* STYLES FOR PASSWORD BUTTON */
.ant-input-password.sty-inputPassForm{
  background-color: $color_white !important;
  border-color: shades($color_ligthGray5, "d1") !important;
  border-radius: 15px;
  padding-top: 0;
  padding-bottom: 0;
  border-style: solid;
  margin-bottom: 12px;

  &:hover{
    // background-color: $color_white !important;
    box-shadow: $shadow_input !important;
    border-color: shades($color_ligthGray5, "d2") !important;
  
    & input.ant-input{
      background-color: $color_white !important;
    }
  }

  & input.ant-input{
    color: $color_blackBlueBackground !important;
    font-size: 14px !important;
    background-color: $color_white !important;//$color_ligthGray5 !important;

    &:hover{
      background-color: $color_white !important;
    }
  }
}

/* END STYLES FOR PASSWORD BUTTON */

/* STYLES FOR THE MARKER BAR TO PASSWORD VALIDATION*/
.input-password__marker {
  display: flex;
  margin-bottom: 15px;
}

.input-password__marker > div:first-child {
  margin-left: 0;
}

.input-password__marker > div:last-child {
  margin-right: 0;
}

.input-password__marker > div {
  background-color: #F2F2F2;
  border-radius: 10px;
  height: 8px;
  width: 33%;
  flex: 1;
  margin: 0 4px;
}

.input-password__marker > div.danger {
  background-color: #F79682;
}

.input-password__marker > div.warning {
  background-color: #F7BC1C;
}

.input-password__marker > div.success {
  background-color: #17D499;
}

/* END STYLES FOR THE MARKER BAR TO PASSWORD VALIDATION*/



.sty-invalidPhone {
  &.disabled {
    background-color: shades($color_ligthGray5, "d1") !important;
    border-color: shades($color_ligthGray5, "d2") !important;
  }
  font-size: 14px !important;
  border-color: shades($color_ligthGray5, "d1") !important;
  border-radius: 15px!important;
  border-style: solid!important;
  background-color: $color_white !important;
  &:hover {
    border-color: shades($color_ligthGray5, "d2") !important;
  }
  &:focus {
    box-shadow: $shadow_input !important;
  }
}

.react-tel-input .invalid-number-message {
  background: transparent!important;
  font-size: 12px!important;
  font-weight: 500!important;
  z-index: 1!important;
  left: 55px!important;
  top: -11px!important;
}

.react-tel-input .form-control{
  border-radius: 15px;
  height: 35px!important;
}

.react-tel-input .flag-dropdown{
  background-color: transparent!important;
  border:0!important;
}

.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
  background-color: transparent!important;
}

.react-tel-input .selected-flag {
  padding-left: 10px;
}

.sty-inputForm.sty-invalidPhone {
  &.disabled {
    background-color: shades($color_ligthGray5, "d1") !important;
    border-color: shades($color_ligthGray5, "d2") !important;
  }
  font-size: 14px !important;
  border-color: shades($color_ligthGray5, "d1") !important;
  border-radius: 15px;
  border-style: solid;
  &:hover {
    border-color: shades($color_ligthGray5, "d2") !important;
    background-color: #fff !important;
  }
  &:focus {
    box-shadow: $shadow_input !important;
    background-color: #fff !important;
  }
}

input:-webkit-autofill{
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
}

input:-webkit-autofill.disabled{
  -webkit-box-shadow: 0 0 0 30px #d8d5da inset !important;
  border-color: #b1acb7 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}


.sty-resendCode {
  color: $color_blue;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.countCaptcha {
  z-index: 2;
  font-size: 36px;
  display: block;
  font-weight: 700;
  text-align: center;
  /* vertical-align: middle; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color_ligthGray;
  border-color: shades($color_secondaryFonts, "d2") !important;
}

.panelNotification{
  padding: 18px;
  height: 110px;
  font-size: 22px;
  font-weight: 400;
  text-align: left;
  background-color: $color_ligthGray;
  border-color: shades($color_secondaryFonts, "d2") !important;
}

.panelSolkos{
  padding: 18px;
  height: 110px;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  background-color: $color_ligthGray;
  border-color: shades($color_secondaryFonts, "d2") !important;

}

.panelSolkos p{
  margin-bottom: 0!important;
}

.loginSubmit {
  border: 0;
  border-radius: 15px;
  height: 40px !important;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  background: $color_blackBlue!important;
  cursor: default;
  padding: 0px 8px;
  vertical-align: middle;
  width: 100%;
  outline: none !important;
  color: $color_white !important;

  &:hover {
    cursor: pointer;
    background: $color_blackBlueHover!important;
  }
  &:focus {
    box-shadow: $shadow_input!important;
  }
}

.loginSubmit span {
  -webkit-box-pack: center;
  justify-content: center;
  display: flex !important;
}

.centerSpan {
  align-self: center;
  display: inline-flex;
  flex-wrap: nowrap;
  max-width: 100%;
  position: relative;
  width: 100%;
  -webkit-box-pack: center;
  justify-content: center;
}

.loadElement {
  animation: spin-animation 1.2s infinite;
  display: inline-block;

  @keyframes spin-animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
}

.social-login {
  margin-top: 16px;
}

.social-or {
  color: $color_ligthGray6;
  //content: attr(data-i18n-or);
  display: block;
  font-size: 11px;
  line-height: 1;
  margin-bottom: 16px;
  text-align: center;
  text-transform: uppercase;
}

.socialAccess {
  display: flex;
  align-items: stretch; /* Default */
  justify-content: space-between;
  width: 100%;
  padding: 0px 35px 0px 35px;
  margin: 0;
}
.socialAccess li {
  display: block;
  flex: 0 1 auto; /* Default */
  list-style-type: none;
  border-width: 0px;
  border-radius: 15px;
  background: $color_white;
  box-shadow: $shadow_black2 !important;

  &:hover {
    background: $color_ligthGray3 !important;
    box-shadow: $shadow_black2 !important;
  }
}

.socialIcons {
  height: 18px;
  width: 18px;
}

.loginFooter {
  color: $color_secondaryFonts;
  padding-top: 16px;
  margin-bottom: 32px;
  border-bottom: 1px solid $color_ligthGray5;
  font-size: 14px;
  text-align: center;
  line-height: 20px;
}

.resendCode ul{
  display:flex;
  font-size: 14px;
  justify-content: left!important;
  list-style: none;
  padding: 0px;
}

.loginFooter ul {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  list-style: none;
  padding: 0px;
}



.form-control:focus {
  outline: 0 !important;
  border-color: initial;
  box-shadow: none;
}


