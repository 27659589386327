@import './shortenings';

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  font-family: "Open Sans", sans-serif;
  color: $color_globalFonts;
  
}

a {
  color: $color_blackBlue;
}

a:hover {
  color: $color_blackBlueHover;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif;
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 25px 0;
  overflow: hidden;
}

.section-bg {
  background-color: $color_ligthGray;
}

.section-title {
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: $color_redSolkos1;
  bottom: 0;
  left: 0;
}

.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Disable AOS delay on mobile
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/

.breadcrumbs {
  padding: 15px 0;
  background: #f6f6f7;
  margin-top: 100px;
}

.breadcrumbs h2 {
  font-size: 24px;
  line-height: 1;
  font-weight: 400;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol li + li {
  padding-left: 10px;
}

.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #4f4c55;
  content: "/";
}

@media (max-width: 768px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }
  .breadcrumbs ol {
    display: block;
  }
  .breadcrumbs ol li {
    display: inline-block;
  }
}

.primarySolkos{
  transition: .5s;
  -webkit-animation-delay: .8s;
  animation-delay: .8s;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  background: #0098C4;
  color: #FFFFFF !important;
  border: 0;

  &:hover{
    cursor: pointer;
    background: #0A84B0;
  }
}

.sty-alertMsg{
  min-width: 300px;
  z-index: 9999;
  position: absolute;
  right: 10px;
  margin-top: 10px;
}

.disabled {
  cursor: not-allowed !important;
  pointer-events: none !important;
}

.alert-success{
  height: 16px;
  width: 16px;
  background-color: #86E9DF;
}

.alert-warning{
  height: 16px;
  width: 16px;
  background-color: #ffcc00;
}

.alert-error{
  height: 16px;
  width: 16px;
  background-color: red;
}

.cursor {
  cursor: pointer!important;
}

.sty-notificationAlert{

  .anticon.anticon-check-circle{
    color: #3dccbd!important;
  }
  
  .ant-notification-notice-description{
    width: 82%;
  }
}

/*--------------------------------------------------------------
# Table
--------------------------------------------------------------*/


.ant-table-thead > tr > th{
  background: #f0f2f5!important;
}


.ant-table-thead > tr > th{
  border-top: 1px solid #dcdcdc!important;
  border-bottom: 1px solid #dcdcdc!important;
}

tbody.ant-table-tbody{
  border: 1px solid #dcdcdc!important;
}

.ant-table-tbody{
  background: #fafafa!important;
}
.ant-table-tbody > tr.ant-table-row-level-0:hover > td {
  background: #e7e7e7!important;
}

.ant-pagination-item-active a {
  color: #969696!important;
}

.ant-pagination-item-active {
  font-weight: 500;
  background: #fafafa!important;
  border-color: #d1d1d1!important;
}

.ant-table-placeholder{
  height: 60vh;
}


/*--------------------------------------------------------------
# Tag
--------------------------------------------------------------*/
.ant-tag{
  border-radius: 8px!important;
}