.ant-layout{
    background: none;
}

footer {
    background-color: black;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
    color: white;

    &div{
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
    }
  }