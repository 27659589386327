@import "../variables";

.sty-card-form{
    border-color: shades($color_ligthGray5, "d1") !important;
    border-radius: 17px;
}

.sty-card-board .ant-card-meta-title {
    margin-bottom: 2px!important;
}

.sty-card-board .ant-card-meta-description{
    text-overflow: ellipsis !important;
    height: 4rem!important;
}

.ant-card-body {
    padding: 18px 24px 24px;
}

.primarySolkos.sty-boardButton{
    margin-top: 8px;
    background: #ec5078!important;
    border-color: #e03b4e!important;
    border-radius: 15px;

    &:hover, &:focus{
        color: #fff!important;
        background: #f4648c!important;
        border-color: #e82d5d!important;
    }
}

